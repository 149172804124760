<template>
  <div>
    <FormulateForm #default="{ hasErrors }" class="min-h-full flex-1">
      <div class="flex flex-col justify-between min-h-full">
        <div class="form flex w-full flex-col flex-1">
          <FormulateInput
            class="w-full"
            label="Field Name"
            :disabled="fieldData && fieldData.is_restricted || queryData=='view'"
            placeholder="Field Name"
            type="text"
            validation="required"
            :element-class="
              (context, classes) => ['flex-1 min-w-full'].concat(classes)
            "
            v-model="fieldData.name"
          />
          <FormulateInput
            class="w-full"
            label="Field Label"
            :disabled="fieldData && fieldData.is_restricted || queryData=='view'"
            placeholder="Field Label"
            type="text"
            validation="required"
            :element-class="
              (context, classes) => ['flex-1 min-w-full'].concat(classes)
            "
            v-model="fieldData.label"
          />
          <FormulateInput
            class="w-full testClass"
            label="Info Text"
            placeholder="Info Text"
            type="rich-text"
            validation="required"
            :element-class="
              (context, classes) => ['flex-1 min-w-full'].concat(classes)
            "
            v-model="fieldData.placeholder"
          />
          <FormulateInput
            class="w-full"
            label="Description"
            :disabled="fieldData && fieldData.is_restricted || queryData=='view'"
            :element-class="
              (context, classes) => ['flex-1 min-w-full'].concat(classes)
            "
            placeholder="Description"
            type="textarea"
            v-model="fieldData.description"
          />
        </div>

        <div class="card-actions justify-end" v-if="!fieldData.is_restricted">
          <Button
            text="Save"
            type="primary"
            :disabled="hasErrors"
            @click.native="$emit('saveCreateField')"
          />
        </div>
      </div>
    </FormulateForm>
  </div>
</template>

<style scoped>
</style>

<script>
const Button = () => import("@/components/button");

export default {
  name: "label-info-rich-text",
  props: ['fieldData','queryData'],
  components:{
      Button,
  }
};
</script>
